/* eslint-disable @next/next/no-img-element */
/* eslint-disable max-len */
import React, { useState } from "react";
import styles from "./styles.module.css";
import commonStyles from "../../../styles/commonStyles.module.css";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import Image from "next/image";

const tasks = [
  {
    title: msg`Teacher Dashboard`,
    desc: msg`Effortlessly manage your teaching tasks. Stay organized and focused with easy access to essential tools and resources, eliminating the need to navigate multiple systems.`
  },
  {
    title: msg`Lesson Planner`,
    desc: msg`Simplify lesson planning with our efficient Lesson Planner. Save time creating and managing lesson plans, empowering you to deliver quality education without the administrative burden.`
  },
  {
    title: msg`Deliver On Any Devices`,
    desc: msg`Easily host video conferences, leave feedback, start discussions, send messages, and more. Let learners access courses from anywhere, whether a desktop, laptop, tablet or mobile.`
  }
];

const AdminTask: React.FC = () => {
  const [active, setActive] = useState(0);
  const { i18n } = useLingui();

  const getStyle = () => {
    switch (active) {
      case 0:
        return styles.listTask1;
      case 1:
        return styles.listTask2;
      case 2:
        return styles.listTask3;
      default:
        break;
    }
  };

  return (
    <div className={styles.adminTaskContainer}>
      <div className={commonStyles.container}>
        <div className={styles.title}><Trans>Simplify admin tasks to get back your precious time</Trans></div>
        <div className={styles.buttons}>
          {
            tasks.map((task, index) => (
              <div
                key={index} 
                onClick={() => setActive(index)} 
                className={`${commonStyles.buttonNoBorder} ${active === index ? styles.active : ''}`}
              >
                {i18n._(task.title)}
              </div>
            ))
          }
        </div>
        <div className={`${styles.listTask} ${getStyle()}`}>
          {
            tasks.map((task, index) => (
              <span key={index} 
                className=
                  {
                    index === active ? `${styles.taskItem} ${styles.taskActive}` : styles.taskItem
                  }
              >
                <section>
                  <div className={styles.taskTitle}>
                    {i18n._(task.title)}
                  </div>
                  <div className={styles.taskDesc}>
                    {i18n._(task.desc)}
                  </div>
                </section>
                <div className={styles.img}>
                  <Image width={483} height={476} alt="task" src={`/v3/tasks/${index + 1}.svg`}  />
                </div>
              </span>
            ))
          }
          <div className={styles.circle}>
            <div style={{ position: 'relative', height: '100%' }}>
              <Image
                src="/v3/tasks/circle.svg"
                alt="bg"
                layout="fill"
                objectFit="cover" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTask;
