import AdminTask from "components/LandingPage/AdminTask";
import Faqs from "components/LandingPage/Faqs";
import FeedBack from "components/LandingPage/FeedBack";
import type { GetServerSidePropsContext, GetStaticProps, GetStaticPropsContext, NextPage } from "next";
import Head from "next/head";

import dynamic from "next/dynamic";
import { useEffect, useRef, useState } from "react";
import Layout from "components/Layout";
import SplashLoading from "components/SplashLoading";
import { loadCatalog } from "utils/i18n";

const FeaturesWithNoSSR = dynamic(
  () => import("../components/LandingPage/Features"),
  {
    ssr: false,
  }
);

const HeroSectionWithNoSSR = dynamic(
  () => import("../components/LandingPage/HeroSection"),
  {
    ssr: false,
  }
);

const Home: NextPage<any> = (props: {
  locale: string;
}) => {
  const featureRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState<any>(null);
  const [activeHeader, setActiveHeader] = useState<any>(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (
      featureRef.current &&
      !active &&
      active === null &&
      position > featureRef.current?.offsetTop
    ) {
      setActive(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [active]);

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <Head>
        <title>ZenClass - Teach Your Way</title>
        <meta
          name="description"
          content="Teach your way - The adaptive learning platform that makes education brilliant"
        />
        <link rel="icon" href="/favicon.ico" />

        <meta property="og:title" content="ZenClass - Teach Your Way" />
        <meta property="og:image" content="https://zenclass.co/v3/logo.jpg" />
        <meta 
          property="og:description" 
          content="Teach your way - The adaptive learning platform that makes education brilliant" 
        />

        <meta name="twitter:title" content="ZenClass - Teach Your Way" />
        <meta name="twitter:image" content="https://zenclass.co/v3/logo.jpg" />
        <meta 
          name="twitter:description"
          content="Teach your way - The adaptive learning platform that makes education brilliant" 
        />
      </Head>
      <Layout
        activeHeader={activeHeader}
        activeNoti={active}
        locale={props.locale}
      >
        <SplashLoading />
        <HeroSectionWithNoSSR
          openHeader={(value) => setActiveHeader(value)}
          activeHeader={activeHeader}
          locale={props.locale}
        />
        <FeedBack />
        <div ref={featureRef}>
          <FeaturesWithNoSSR />
        </div>
        <AdminTask />
        <Faqs />
      </Layout>
    </div>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const translation = await loadCatalog(ctx.locale!);
  return {
    props: {
      translation,
      locale: ctx.locale,
    },
  };
};

export default Home;

