/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Image from "next/image";

const SplashLoading: React.FC = () => {
  const [active, setActive] = useState(false);
  const [background, setBackground] = useState(false);
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    const timerActive = setTimeout(() => {
      setActive(!active);
    }, 1500);

    const timerBackground = setTimeout(() => {
      setBackground(!background);
    }, 4500);

    const timerDisplay = setTimeout(() => {
      setDisplay(false);
      setBackground(false);
    }, 5500);

    return () => {
      clearTimeout(timerActive);
      clearTimeout(timerBackground);
      clearTimeout(timerDisplay);
    };
  }, []);

  return (
    <div
      style={{ pointerEvents: display ? 'unset' : 'none' }}
      className={
        !display
          ? `${styles.splashContainer} ${styles.splashContainerAnimate}`
          : styles.splashContainer
      }
    >
      <div
        className={
          background
            ? `${styles.backgroundCircle} ${styles.backgroundCircleAnimate}`
            : styles.backgroundCircle
        }
      />
      {display && (
        <>
          <div className={styles.body}>
            <div
              className={
                active
                  ? `${styles.fullLogo} ${styles.fullLogoAnimate}`
                  : styles.fullLogo
              }
            >
              <div
                className={
                  active
                    ? `${styles.circle} ${styles.circleAnimate}`
                    : styles.circle
                }
              />
              <div className={styles.shadow}> 
                <Image
                  src="/v3/splash/shadow.svg"
                  alt="shadow"
                  layout="fill"
                />
              </div>
              <div className={styles.logo}>
                <Image
                  width={115}
                  height={150}
                  src="/v3/splash/logo/logo.svg"
                  alt="logo"
                />
              </div>
              <div className={styles.left}>
                <Image
                  src="/v3/splash/logo/left.svg"
                  alt="left"
                  width={47}
                  height={63}
                />
              </div>
              <div className={styles.right}>
                <Image
                  width={57}
                  height={44}
                  src="/v3/splash/logo/right.svg"
                  alt="right"
                />
              </div>
              <div className={styles.z}>
                <Image layout="fill" src="/v3/splash/logo/z.svg" alt="z" />
              </div>
              <div className={styles.e}>
                <Image layout="fill" src="/v3/splash/logo/e.svg" alt="e" />
              </div>
              <div className={styles.n}>
                <Image layout="fill" src="/v3/splash/logo/n.svg" alt="n" />
              </div>
              <div className={styles.c}>
                <Image layout="fill" src="/v3/splash/logo/c.svg" alt="c" />
              </div>
              <div className={styles.c2}>
                <Image layout="fill" src="/v3/splash/logo/c.svg" alt="c" />
              </div>
              <div className={styles.l}>
                <Image layout="fill" src="/v3/splash/logo/l.svg" alt="l" />
              </div>
              <div className={styles.a}>
                <Image layout="fill" src="/v3/splash/logo/a.svg" alt="a" />
              </div>
              <div className={styles.s}>
                <Image layout="fill"  src="/v3/splash/logo/s.svg" alt="s" />
              </div>
              <div className={styles.s}>
                <Image layout="fill" src="/v3/splash/logo/s.svg" alt="s" />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SplashLoading;
