/* eslint-disable max-len */
/* eslint-disable @next/next/no-img-element */
import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import commonStyles from "../../../styles/commonStyles.module.css";
import Image from "next/image";
import { Trans } from "@lingui/macro";

const feedbacks = [
  {
    avt: 1,
    name: "Nguyen Dinh Buu Tai",
    desc: "ZenClass is highly customizable. We can get really creative with how we can use the platform to meet our academ­­ic & teaching demands.",
    pos: "ESL Teacher - Le Quy Don High School for the Gifted",
  },
  {
    avt: 2,
    name: "Soline Linh Le",
    desc: "It is very convenient to have an all-in-one-platform with simple interface, straightforward functions, and live update function. Also, ZenClass offers great customer support!",
    pos: "Associate Lecturer - RMIT University Vietnam",
  },
  {
    avt: 3,
    name: "Quynh Nguyen",
    desc: "What I am impressed the most is that ZenClass integrates all functions in the same place, which makes teachers’ work more effective.",
    pos: "Trainer and Founder - Montessori World English Language Center",
  },
  {
    avt: 4,
    name: "Khanh Nhi",
    desc: "ZenClass is an amazing platform that has the potential to revolutionize the way we learn and teach, making it a must-try for anyone looking to improve their educational experience.",
    pos: "ESL Teacher - KTC , M.A in Applied Linguistics",
  },
];

const FeedBack: React.FC = () => {
  const [active, setActive] = useState<any>(0);
  const refWidth = useRef<any>();

  const handleScroll = (value: number) => {
    if (refWidth.current) {
      if (refWidth.current.offsetWidth > 1100) {
        if (value < 0) return setActive(1);
        if (value > 1) return setActive(0);
        setActive(value);
      } else {
        if (value < 0) return setActive(3);
        if (value > 3) return setActive(0);
        setActive(value);
      }
    }
    setActive(value);
  };

  return (
    <div ref={refWidth} className={styles.feedBackContainer}>
      <div className={commonStyles.container} style={{ position: "relative" }}>
        {
          <div
            onClick={() => handleScroll(active - 1)}
            className={styles.arrowLeft}
            style={{ width: 32, height: 32, minWidth: 32 }}
          >
            <Image src="/v3/arrow.png" alt="arrow" layout="fill" />
          </div>
        }
        <div style={{ overflow: "hidden", maxWidth: 1010, margin: "auto" }}>
          <div
            className={`${styles.listCard}`}
            style={{
              transform: `translateX(calc(-${active * 10}0% - ${
                active * 20
              }px))`,
            }}
          >
            {feedbacks.map((feed, index) => (
              <div key={index} className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.top}>
                    <div
                      style={{
                        width: 60,
                        height: 60,
                        position: "relative",
                        minWidth: 60,
                      }}
                    >
                      <Image
                        alt="avt"
                        layout="fill"
                        src={`/v3/feedback/${feed.avt}.png`}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: 4,
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <Trans>{feed.name}</Trans>
                      </div>
                      <div>
                        <Trans>{feed.pos}</Trans>
                      </div>
                    </div>
                  </div>
                  <img src={`/v3/feedback/logo${feed.avt}.png`} alt="logo" />
                </div>
                <div className={styles.des}>
                  <Trans>{feed.desc}</Trans>
                </div>
              </div>
            ))}
          </div>
          {/* <img className={styles.scrollDown} src="/v3/feedback-scroll-down.svg" alt="scroll" /> */}
        </div>
        {
          <div
            onClick={() => handleScroll(active + 1)}
            className={styles.arrowRight}
            style={{ width: 32, height: 32, minWidth: 32 }}
          >
            <Image src="/v3/arrow-2.png" alt="arrow" layout="fill" />
          </div>
        }
      </div>
    </div>
  );
};

export default FeedBack;
